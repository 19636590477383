export const labels = {
  appTitle: "Warex",
  warehouse: "Warehouse",
  transporter: "Transporter",
  transport: "Transport",
  proceed: "Proceed for OTP",
  continue: "Continue",
  clear: "Clear",
  exisitingUser: "New and Existing Customers",
  guestLogin: "Guest Login",
  demoforNewUser: "Full Product Demo for New Users",
  notifications: "Notification",
  demo_string:
    "amet, consetetur sadipscing elitr, sed diam nonumy eirmod temporinvidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duodolores et ea rebum. Stet clita kasdgubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet",
  monthlyRentalText: "Monthly rental",
  addonFeesText: "Addnl. Costs",
  totalAmountText: "Total amount",
  transportServiceNeededLabelText: "Do you need a transport service?",
  pickupLocationLabelText: "Pickup location",
  pickupLocationPlaceholderText: "Select Pickup location from maps",
  destinationLabelText: "Destination",
  destinationPlaceholderText:
    "Select desired area of the warehouse within the city",
  inboundDateLabelText: "Inbound date",
  datePlaceholderText: "Enter date",
  outboundDateLabelText: "Outbound date",
  materialDetailsLabelText: "Material details",
  materialDetailsPlaceholderText: "Select material",
  sortByPlaceholderText: "Select sort",
  packagingTypeLabelText: "Packaging type",
  packagingPlaceholderText: "Select packaging",
  itemDescriptionLabelText: "Item description",
  itemDescriptionPlaceholderText: "Add item description",
  dimensionPlaceholderText: "Select dimension",
  dimensionLabelText: "Package dimensions",
  noOfPackagesLabelText: "No. of Packages",
  totalWeightOfMaterialLabelText: "Total Weight of material",
  totalWeightOfMaterialPlaceholderText: "Enter weight in Tons (1Ton=1000KGs)",
  noOfPackagesPlaceholderText: "Enter no of packages",
  spaceTypePlaceholderText: "Select storage type",
  spaceTypeLabelText: "Storage type",
  spaceOrPalletsAccommodated: "Space or Pallets to be accommodated?",
  spaceOrPalletsAccommodatedPlaceholderText:
    "Enter Area in Sft or No. of Pallets",
  additionalServicesLabelText: "Additional services",
  showListingsButtonText: "Show listings",
  findTrucksButtonText: "Find Trucks",
  sendRFQButtonPluralLabelText: "Send RFQs",
  sendRFQButtonSingleLabelText: "Send RFQ",
  bookOrderButtonLabelText: "Book Order",
  moveMapToAdjustLocation: "Move map to adjust location",
  confirm: "Confirm",
  enterMobileNumber: "+91 | Enter mobile number",
  verifyWithOTP: "Verify with OTP",
  sentTo: "Sent to",
  resentOtpIn: "Resend OTP",
  havingTroubleLoggingIn: " Having trouble logging in?",
  getHelp: "Get Help",
  byContinueIAgree: "By continuing, I agree to the",
  termsOfUse: "Terms of Use",
  privacyPolicy: "Privacy Policy",
  login: "Login",
  signup: "Signup",
  storageDetailsTitleText: "Storage details",
  yearEstablishedLabelText: "Year established",
  truckType: "Truck type",
  capacity: "Capacity",
  pricekm: "Price / Km",
  packaging: "Packaging",
  loading: "Loading",
  unloading: "Unloading",
  totalStorageSpaceLabelText: "Total storage space",
  availableSpaceLabelText: "Available space",
  storageTypesAndPricingTitleText: "Storage types & pricing",
  trucksAvailable: "Trucks available",
  squareFeetLabelText: "Square feet",
  palletLabelText: "Pallet",
  boxesLabelText: "Boxes",
  containerLabelText: "Container",
  additionalDetailsTitleText: "Additional details",
  additionalServicesTitleText: "Additional Services",
  moreSearchTitleText: "More warehouse from your search",
  moreTransportSearchTitleText: "More transport from your search",
  storageCost: "Storage Cost",
  transportCost: "Transport Cost",
  additionalCost: "Additional Costs",
  subtotal: "Sub Total",
  gstAt: "GST @",
  spaceRequiredLabelText: "Space required",
  handlingChargesLabelText: "Handling charges",
  transportedFeeLabelText: "Transporter fee",
  servicesAvailableLabelText: "Features Available",
  approvedProductsLabelText: "Approved products",
  bookNowButtonText: "Book now",
  pickupDateLabelText: "Pickup date",
  requestForQuotes: "Request For Quotes",
  viewDetail: "View detail",
  setYourPriceExpectation: "Set your price expectation",
  pricePerKmLabelText: "Price /Km",
  addToCartButtonLabelText: "Add to cart",
  continueWithListing: "Continue with listing",
  goToInquiry: "Go to Inquiry",
  Cart: "Cart",
  add: "Add",
  continueWithoutTransporter: "Continue without Transporter",
  continueWithoutWarehouse: "Continue without Warehouse",
  sortBy: "Sort by",
  sendRFQ: "Send RFQ",
  back: "Back",
  next: "Next",
  showMore: "...show more",
  quantityPallets: "Quantity",
  ratePerPallets: "Rate / per",
  pricePer: "Price /",
  partnerDetails: "Partner details",
  dateRangePlaceholderText: "Select date range",
  pickUpDatePlaceholderText: "Select PickUp date",
  apply: "Apply",
  materialTypeLabel: "Material Type",
  packagingLabelText: "Packaging",
  dimensionAndUnits: "Dimensions & Units",
  spaceType: "Space type",
  areaInSqft: "Area in Sqft",
  productDetails: "Product details",
  pickupTimeApproxLabelText: "Pickup time (Approx)",
  timePlaceholderText: "Select time",
  billingDetails: "Billing details",
  firstNameLabelText: "First Name",
  lastNameLabelText: "Last Name",
  firmsNameLabelText: "Firms Name",
  firmsEmailLabelText: "Firms Email",
  gstinLabelText: "GSTIN",
  contactLabelText: "Contact",
  billingAddressLabelText: "Billing Address",
  cityPlaceholderText: "City",
  countryPlaceholderText: "Country",
  areaPlaceholderText: "Area",
  statePlaceholderText: "State",
  zipCodePlaceholderText: "Zip Code",
  firstNamePlaceholderText: "First name",
  lastNamePlaceholderText: "Last name",
  firmsNamePlaceholderText: "Firms name",
  firmsEmailPlaceholderText: "Enter email ID",
  gstinPlaceholderText: "Enter GSTIN number",
  contactPlaceholderText: "+91 XXXX XX XXXX",
  billingAddressPlaceholderText: "Street Address",
  ratePerUnits: "Rate / per units",
  proceedToPay: "Proceed to pay",
  storageOrderForm: "Storage Order Form",
  nameLabelText: "First & Last name",
  numberLabelText: "Mobile number",
  emailLabelText: "Email ID",
  subjectLabelText: "Subject",
  descriptionLabelText: "Description",
  namePlaceholderText: "Enter your Name",
  numberPlaceholderText: "Enter your Number",
  emailPlaceholderText: "Enter your Email ID",
  subjectPlaceholderText: "Select your reason",
  descriptionPlaceholderText: "Enter the description",
  savedAddress: "Saved address",
  useThisAddress: "Use this address",
  orderNo: "Order no",
  orderDate: "Order date",
  sgst: "SGST",
  cgst: "CGST",
  inWord: "In Word",
  total: "Total",
  customerOrBuyer: "Customer / Buyer",
  storageFacility: "Storage Facility",
  serviceProvider: "Service provider",
  acceptAll: "Accept all ",
  tnc: "Terms & Conditions",
  completeYourOrder: "Complete Your Order",
  reviewOrder: "Review you booking",
  paymentMethod: "Payment method",
  orderSummary: "Order summary",
  gst: "GST",
  grandTotal: "Grand total",
  shippingDate: "Shipping date",
  notificationMsgCount: "You have $COUNT new notification",
  markAllAsRead: "Mark all as read",
  notificationMsgTemplate: "$MESSAGE, dated $DATE.",
  otherReasonPlaceholderText: "Mention the reason",
  cartEmptyButtonText: "Add to Cart",
  emptyCartText: "Your cart is empty. Please add items.",
  closeButton: "Close",
  distanceLabelText: "Distance",
  getUpdatedTotalButtonText: "Get updated total",
  pricingInfo:
    "Warex's Supply Chain As A Service Platform hosts an ERP which has Inventory Management, Purchases, Sales, Vendor/ Customer Management, Invoicing, Accounts Receivable/ Payable tracking and many others. ",
  contactUsforDemoText:
    "Contact Us below to get a customized offer or book a demo through our field executive. ",
  contactUsToBookDemoText:
    "Contact Us below to book a demo through our field executive.  ",
  saasHeaderText1: "SUPPLY CHAIN",
  saasHeaderText2: " AS A SERVICE",
  marketplaceHeaderText1: "SAAS ENABLED",
  marketplaceHeaderText2: " DISTRIBUTION",
  marketplaceSubHeaderText:
    "We increase your Distribution Efficiency. Reduce your Storage and Logistics Cost.",
  inventoryHeaderText1: "Easy-to-use",
  inventoryHeaderText2: " Inventory Management Software",
  skuWiseLedger: "SKU Wise Ledger",
  inventoryPerformance: "Inventory Performance",
  creditHeaderText1: "Avail Credit",
  creditHeaderText2: "Facility through Warex",
  ewaybillHeaderText1: "Generate",
  ewaybillHeaderText2: " E-Waybills",
  ewaybillpara1:
    "Generate E-waybills through seamless integration with GST E-waybill portal. ",
  ewaybillpara2: "Manage E-waybills and get updated with the status on the go.",
  ewaybillpara3:
    "Let our partnered transporters take care of updating E-waybill, assigning truck and delivering your inventory.",
  ewaybillupdate1:
    "Update, Cancel, Extend the E-waybill just by a click of a button.",
  ewaybillupdate2:
    "Reject E-waybills placed on you by your vendors/ customers.",
  erpHeaderText1: "Simple",
  erpHeaderText2: "ERP Software for SMEs"
};

export const error_msg = {
  required: "Field is required",
  onlyDigits: "Must be only digits",
  enterLeast10Digits: "Must be greater than 6000000000",
  enterOnly10Digits: "Cannot exceed 10 digits",
  enterMobileNumber: "Mobile number is required",
  enterOtp:
    "Please enter the OTP sent to your registered mobile number to verify your account.",
  errorSigningMobile: "Error signing in mobile number",
  errorSigningOTP: "Error signing with OTP",
  acceptTnC: "Please accept the terms and conditions.",
  selectReason: "Please select a reason to cancel/close"
};

export const DUMMY_ITEMS = [
  {
    label: "Yes",
    value: "yes"
  },
  {
    label: "No",
    value: "no"
  }
];

export const appConstants = {
  dateFormat: "D MMM, YY",
  orderDateFormat: "D/M/YYYY",
  fullFormat: "D MMMM YYYY",
  timeFormat: "h:mm A",
  sgst: 9,
  cgst: 9
};

export const home_labels = {
  titleText: "Redesign Your Supply Chain",
  titleSubtext:
    "Explore our Marketplace of Warehouses and Transporters for On-Demand Storage and Logistics Solutions",
  findWarehouseButtonText: "Find Warehouse",
  findTruckButtonText: "Find Truck",
  whatAreYouLookingForText: "What are you looking for?",
  howWeSolveText: "How do we Solve ?",
  howItWorksText: "How it works?",
  howWeSolveSubText:
    "We increase your Distribution Efficiency. Reduce your Storage and Logistics Cost.",
  erpTitleText: "Simple ERP Software for SMEs",
  erpTitleSubtext:
    "Designed for Small Businesses, Distributors, Dealers, Wholesalers in FMCG, Apparel, Footwear, Furniture, Chemicals, Hardware, Electricals, Appliances and many more.",
  searchText: "Search",
  searchSubText: "Search for a Warehouse Space and/ or a Truck",
  findText: "Match",
  findSubText: "Find matching spaces and trucks.",
  rfqText: "RFQs",
  rfqSubText: "Request Quotes from selected Partners",
  bookText: "Book",
  bookSubText:
    "Compare Quotes received and Book Order on the Partner you select",
  manageText: "Manage",
  manageSubText:
    "Create Packing Notes, Manage and Get updated with the Order Flow.",
  shipText: "Ship",
  shipSubText: "Ship Partial or Full Consignments as per your need.",
  WarexMarketplace: "Product at a Glimpse",
  WarexERP: "Warex ERP",
  WarexCredit: "Warex CREDIT",
  Explore: "Explore",
  exploreSubtext: "Find warehouses and/ or transporters",
  RFQ: "RFQ",
  RFQSubtext: "Request Quote from any 3 selected partners",
  Quote: "Quote",
  QuoteSubtext: "Partners Competitively bid against the inquiry",
  compareQuotes: "Compare Quotes",
  compareQuotesSubtext:
    "Compare responses/ quotes and place order on the best quote.",
  orderManagement: "Order Management",
  orderManagementSubtext:
    "Manage your order by adding packing note, modify, tracking, view invoices, MRRs, Gatepass etc",
  ship: "Ship",
  shipSubTextonly: "Dispatch Partial or Full Packages",
  Inventory: "Inventory",
  InventorySubTextonly:
    "Manage 1000+ SKUs along with their stock levels and attributes",
  Register: "Warehouse Register",
  RegisterSubTextonly: "Prepare Inwards and Outwards at multiple warehouses",
  Vendor: "Purchases",
  VendorSubTextonly: "Manage Vendors, Purchases and returns",
  SaleOrder: "Sales Orders",
  SaleOrderSubTextonly: "Manage Customers, Quotes, Sales Orders and Returns",
  Performance: "Performance",
  PerformanceSubTextonly:
    "Track SKU level margins, COGS and Stock Value through FIFO method",
  Finance: "Finance",
  FinanceSubTextonly:
    "Manage Accounts Receivables and Payables, raise invoices, Credit, Debit Notes",
  simpleErpTitle: "Simple ERP Software",
  simpleErpText:
    "Use our cloud ERP for SMEs to manage inventory, purchases, sales and billing",
  bookTruckTitle: "Book Truck",
  bookTruckText:
    "Find a truck from our partnered transporters and dispatch without hassle",
  bookWarehouseTitle: "Book Warehouse",
  bookWarehouseText:
    "Book a warehouse and pay per use. We will make your distribution efficient.",
  ewayBillTitle: "E-Waybills",
  ewayBillText:
    "Generate E-waybills and auto assign to our partnered trucks along with packing note and delivery challans.",
  getCreditTitle: "Get Credit",
  getCreditText:
    "Get cash from your unpaid invoices through our partnered NBFCs. Available on platform usage after 3 months.",
  industriesServedText: "Industries served",
  offerText: "What do we offer ?",
  industriesServedSubText:
    "Warex is a Marketplace for Warehouses and Transporters to be accessed by SMEs for their Short term Storage demand",
  "apparel&TextilesText": "Apparel & Textiles",
  "FMCG/F&B": "FMCG / F&B",
  Appliances: "Appliances and Durables",
  AutoComponents: "Auto Components",
  Footwear: "Footwear",
  Furniture: "Furniture",
  showAllButtonText: "Show All",
  creditInvoice: "Warex Credit",
  aboutUsTitleText: "About us",
  learnMoreButtonText: "Learn more",
  contactUsTitleText: "Contact us",
  contactUsSubText1: "Office:",
  contactUsSubText2:
    " 2nd Floor, 91 Springboard, Laxmi Cyber City, White Fields, Kondapur, Hyderabad - 500081.",
  contactNumberText: "+91 - 905 958 8686",
  contactEmailText: "Email : info@warex.in",
  infoEmailText: "Write to : info@warex.in",
  footerSubtext:
    "Conhex Technologies Private Limited CIN : U63030TG2022PTC169538",
  warehousePartnerLabelText: "Warehouse Partner Login",
  warehousePartnerSubText1:
    "Warex hosts a SAAS based marketplace with a network of Warehouses connecting SMEs.",
  warehousePartnerSubText2:
    "Partners can Login and unleash multiple features of our advanced Warehouse Management System.",
  warehousePartnerMobileSubText:
    "Partners can Login and unleash multiple features of our advanced Warehouse Management System.",
  transporterPartnerLabelText: "Transporter Partner Login",
  partnerWithUsTitleText: "Want to Partner with us ?",
  loginViaText: "Login via ",
  mobileOrEmailText: "mobile or email",
  loginFormCaptionText: "By continuing, I agree to the ",
  termOfUseText: "Terms of Use",
  privacyPolicyText: "Privacy Policy",
  forgotPasswordText: "Forgot password?"
};

export const filter_labels = {
  selectWarehouseLocation: "Select Warehouse Location",
  selectPickupLocation: "Select Pickup Location",
  selectDropLocation: "Select Drop Location",
  pickupDate: "Pickup Date",
  inboundOutboundDate: "Inbound and Outbound Dates",
  storageQuantity: "Storage Quantity",
  materialCategory: "Material Category",
  services: "Approx. Weight and other Services"
};

export const inquiry_table_labels = {
  manageInquiry: "Manage Inquiry",
  searchPlaceholderText: "Search by inquiry ID, etc",
  close: "Close",
  addNewInquiry: "Add new Inquiry",
  dated: "Dated",
  inquiryId: "Inquiry ID",
  view: "View",
  cancel: "Cancel",
  book: "Book",
  proposedPrice: "Your proposed price",
  confirmCloseInquiry: "Are you sure you want to close this Inquiry?",
  confirmCloseInquirySubText:
    "This will delete the Inquiry permanently. You cannot undo this action later."
};

export const order_table_labels = {
  manageOrders: "Manage Orders",
  searchPlaceholderText: "Search by order ID partner name, etc",
  view: "View",
  cancelled: "Cancelled",
  retrieveOrders: "Retrieve Orders",
  createPackingNotes: "Create Packing Notes",
  modifyOrder: "Modify Order",
  cancelOrder: "Cancel Order",
  retrieveOrder: "Retrieve Order",
  trackOrder: "Track Order",
  viewDetails: "View details",
  orderDetails: "Order details",
  viewInvoicesAndDocs: "View Invoices & docs",
  orderId: "Order ID",
  orderDate: "Order date",
  cancel: "Cancel",
  orderEditModeWarningMsg:
    "Change in date and location is subjected to the change in overall amount. ",
  order: "Order",
  storageCosts: "Storage Costs",
  additionalCosts: "Additional Costs",
  previousTotal: "Previous Total",
  updatedTotal: "Updated Total",
  pay: "Pay",
  toContinue: "to continue",
  packingNotes: "Packing notes",
  packageNameLabel: "Package name",
  packageSizeLabel: "Package size",
  addPackageNamePlaceholder: "Add package name",
  packingMaterialLabel: "Packing Material",
  materialCategoryLabel: "Material Category",
  orderNo: "Order No",
  qrCodePlaceholderLabel: "Save packing note to generate QR",
  itemNamePlaceholder: "Item name",
  categoryPlaceholder: "Sub Category",
  quantityPlaceholder: "Quantity",
  addMoreItems: "Add more Items",
  print: "Print",
  editNote: "Edit note",
  savePackingNote: "Save packing note",
  addMorePackages: "Add more packages",
  id: "ID",
  retrievePackages: "Retrieve packages",
  selectPackages: "Select packages",
  retrieveAll: "Retrieve all",
  retrieveSelectedPackage: (count) => `Retrieve selected package (${count})`,
  modifyRetrieveOrderShippingDateWarningMsg:
    "Modify order for changing shipping date beyond outbound date",
  requestOutbound: "Request Outbound",
  checkRetrievalStatus: "Check retrieval status",
  bookATransporter: "Book a transporter",
  downloadGatepass: "Download Gatepass",
  amount: "Amount",
  status: "Status",
  retrieveDate: "Retrieve date"
};

export const invoice_and_docs_labels = {
  createdOn: "Created on",
  print: "Print",
  download: "Download",
  documents: "Documents",
  paymentLedger: "Payment Ledger",
  orderDetails: "Order Details",
  warehouseOrder: "Warehouse Order",
  transporterOrder: "Transporter Order",
  invoiceDetails: "Invoice Details",
  warehouseInvoice: "Warehouse Invoice",
  transporterInvoice: "Transporter Invoice",
  yourPayments: "Your Payments",
  paymentAmount: "Payment Amount",
  transactionID: "Transaction ID",
  toBePaid: "To be paid",
  payNow: "Pay now"
};

export const customer_table_labels = {
  addNewCustomer: "Add New Customer",
  addNewCustomerMobile: "+ Customer",
  brands: "By Brands",
  customerName: "By Customer Name",
  category: "By Category",
  product: "By Product",
  sku: "By SKU",
  name: "By Name"
};

export const inventory_table_labels = {
  addNewSku: "Add New SKU",
  Sku: "+ SKU",
  excelUpload: "Excel Upload",
  templateDownload: "Download Sample",
  cloneSku: "Clone SKU",
  applyFilters: "Apply Filters",
  brands: "By Brands",
  category: "By Category",
  product: "By Product",
  sku: "By SKU",
  warehouse: "By Warehouse",
  dates: "From and To Dates",
  pickDates: "Pick Dates",
  attribute1: "By Attribute1",
  attribute2: "By Attribute2",
  name: "By Name",
  vendorName: "By Vendor Name"
};

export const register_table_labels = {
  warehouse: "By Warehouse",
  docType: "By Doc.Type",
  docNumber: "By Doc. No",
  status: "By Status",
  date: "From and To Dates",
  pickDates: "Pick Dates"
};

export const sku_labels = {
  productName: "Product Name",
  brandName: "Brand Name",
  categoryName: "Category Name",
  attribute1: "Attribute1",
  attribute2: "Attribute2",
  attribute3: "Attribute3",
  units: "Units",
  gst: "GST",
  sku: "SKU",
  hsn: "HSN",
  upc: "UPC",
  listPrice: "List Price",
  minimumStockLevel: "Minimum Stock Level",
  currentStockLevel: "Beginning Stock",
  uploadButtonText: "Upload Pic"
};

export const action_labels = {
  outboundRequestSuccess: "Outbound requested successfully!",
  yourInquiryCancelled: "Your inquiry is cancelled!",
  rfqSentToWarehousesSuccess: "RFQ sent to warehouses successfully!",
  warehousesRequestRespondMsgSuccess:
    "You can expect a response in 4-5 working hours from the warehouses.",
  customerRequestInquiryResponseTime:
    "You can expect a response in 4-5 working hours from the customer.",
  yesRegretButtonText: "Yes, regret",
  orderCreatedText: "Order Created Successfully and Sent to the Partner",
  navigateToMyOrdersButtonText: "Go to Orders",
  paymentSuccessText: "Payment made successfully!"
};

export const statusKeys = {
  Completed: "Completed",
  ToBePickedUp: "ToBePickedUp",
  ToBePicked: "ToBePicked",
  Cancelled: "Cancelled",
  VehicleAssigned: "VehicleAssigned",
  InTransit: "InTransit",
  ReceivedAtFacility: "ReceivedAtFacility",
  Stored: "Stored",
  Generated: "Generated",
  PartiallyRetrieved: "PartiallyRetrieved",
  PartialReceived: "PartialReceived",
  PartialDispatched: "PartialDispatched",
  PartialDelivered: "PartialDelivered",
  Adjusted: "Adjusted",
  Submitted: "Submitted",
  Partial: "Partial",
  InvoicePending: "InvoicePending",
  Paid: "Paid",
  Responded: "Responded",
  Regret: "Regret",
  Pending: "Pending",
  QuoteSent: "QuoteSent",
  YetToBePicked: "YetToBePicked",
  Received: "Received",
  GatePassRequested: "GatePassRequested",
  GatePassPrepared: "GatePassPrepared",
  OrderBooked: "OrderBooked",
  Placed: "Placed",
  PartiallyReceived: "PartiallyReceived",
  Dispatched: "Dispatched",
  PartiallyDispatched: "PartiallyDispatched",
  Lost: "Lost",
  Converted: "Converted",
  QuotesSent: "Quote Sent",
  Active: "Active",
  InActive: "Inactive",
  Draft: "Draft",
  Delivered: "Delivered",
  Expired: "Expired",
  SoCancelled: "Sale Order Cancelled",
  Approved: "Approved"
};

export const profile_labels = {
  personalDetails: "Setup your Business/ User Profile",
  businessAddress: "Business Address",
  address: "Address",
  otherSavedAddress: "Other saved Address",
  saveChanges: "Save changes",
  cancel: "Cancel",
  edit: "Edit",
  firstNameLabelText: "First Name",
  lastNameLabelText: "Last Name",
  firmsNameLabelText: "Company Name",
  emailLabelText: "Email",
  gstinLabelText: "GSTIN",
  phoneLabelText: "Phone",
  brandsLabelText: "Brands",
  annualTurnoverLabelText:
    "Annual Turnover (Helps us in setting up right GST compliance features)",
  brandsPlaceholderText: "Add brands",
  productsLabelText: "Products",
  MaterialsLabelText: "Materials",
  productsPlaceholderText: "Select products",
  cityPlaceholderText: "City",
  areaPlaceholderText: "Area",
  zipCodePlaceholderText: "Zip Code",
  uploadLogo: "Upload Logo",
  firstNamePlaceholderText: "First name",
  lastNamePlaceholderText: "Last name",
  firmsNamePlaceholderText: "Enter Company name",
  emailPlaceholderText: "Enter email ID",
  gstinPlaceholderText: "Enter GSTIN number",
  phonePlaceholderText: "+91 XXXX XX XXXX",
  verified: "Verified",
  natureOfBusinessLabelText: "Nature of business",
  natureOfBusinessPlaceholderText: "Select nature of business",
  streetAddressLabelText: "Door/ Building/ Street/ Area",
  streetAddressPlaceholderText: "Enter Street address",
  stateLabelText: "State",
  setAsDefault: "Set as default"
};

export const contactUsSubject = [
  { displayName: "Inquiry", name: "Inquiry" },
  {
    displayName: "Explore Warex’s Solutions",
    name: "Explore Warex’s Solutions"
  },
  { displayName: "Partner with Warex", name: "Partner with Warex" },
  { displayName: "Service and Support", name: "Service and Support" },
  { displayName: "Others", name: "Others" }
];

export const packersMovers_label = {
  titleText: "Packers and Movers in",
  titleHyderabad: "Hyderabad",
  titleBengaluru: "Bengaluru",
  storeButtonText: "STORE",
  shiftButtonText: "SHIFT",
  storeButtonSubText: "Looking to Store goods ?",
  shiftButtonSubText: "Moving into New Home ?",
  commonShiftDescriptionText1:
    "by setting your own budget. We provide complete relocation services with both inter-city and intra-city movements",
  bengaluruShiftDescription:
    " - Be it near Koramangala or Yeshwantpura, shift your home now without any hassles! Warex provides packing and moving services across Bangalore. Explore our partners and ",
  hyderabadShiftDescription:
    " - Be it near Hitech City or Charimnar, shift your home now without any hassles! Warex provides packing and moving services across Hyderabad. Explore our partners and ",
  storeDescription:
    " - Our network of warehouses provide safe storage services for your household items, business goods and other industrial material. You can find the best space at your budget and ",
  secondaryDescription:
    "Our portal enables customers to send enquiries and receive competitive bids directly from various partners. Customers can prepare a detailed packing note and track each package. Our Request For Quote system enables customers to negotiate with multiple vendors and get services at cheapest prices. Please go through our Product-Explainer-Video to know about various features of Warex. "
};

export const Assets_label = {
  addWarehouses: "Add Warehouses",
  addNewWarehouse: "Add New Warehouse",
  addTrucks: "Add Trucks",
  removeEmptyVehicle: "Remove Empty Vehicle",
  cancel: "cancel",
  edit: "Edit",
  save: "Save",
  mapWarehouse: "Map your warehouse location",
  address: "DNo/ Building/ Street/",
  area: "Area",
  city: "City",
  state: "State",
  pin: "PIN",
  code: "Code/ Area (6 Alpha-num)",
  ownership: "Select Owner Ship",
  storage: "Area"
};

export const createMrr_labels = {
  invoiceNo: "Invoice No :",
  invoiceDate: "Invoice/Delivery Date :",
  mobileInvoiceDate: "Invoice Date :",
  invoiceAmount: "Invoice Amount :",
  truckRegNo: "Truck Reg No.:",
  remarks: "Remarks",
  attachment: "Upload Attachments"
};

export const eWayBills_labels = {
  createEWaybillButton: "+ Create E-Waybill",
  supplyType: "By Supply Type",
  status: "By Status",
  date: "By date",
  ewayBillNo: "By Ewaybill. No ",
  gstin: "By GSTIN"
};

export const EwayBillForm_labels = {
  supplyType: "Supply Type",
  subType: "Sub Type",
  docType: "Doc. Type",
  TransportType: "Trans. Ty",
  docNo: "Doc. No.",
  docDate: "Doc. Date",
  Name: "Name",
  Gstin: "GSTIN",
  State: "State",
  shipFrom: "Ship From",
  Place: "Place",
  PIN: "PIN",
  shipTo: "Ship To",
  transporterId: "Transporter ID",
  transporterName: "Transporter Name",
  distance: "Approx.|Distance in KM",
  mode: "Mode",
  vehicleType: "Vehicle Type",
  vehicleNo: "Vehicle No.",
  transportDocNo: "Transport Doc. No.",
  transportDocDate: "Doc. Date"
};

export const purchase_labels = {
  addNewVendorButton: "+ Add New Vendor",
  addVendor: "+ Vendor",
  vendorDetailsLabel: "Vendor Details",
  addVendorLabel: "Add Vendor",
  vendorCompanyName: "Vendor's Company  Name",
  vendorCompanyNamePlaceholder: "Vendor's Company  Name",
  contactName: "Contact  Name",
  contactNamePlaceholder: "Enter contact name",
  contact: "Contact",
  contactPlaceholder: "Enter Mobile no",
  email: "Email id",
  emailPlaceholder: "Enter email",
  warehouseAddress: "Warehouse Address",
  warehouseAddressPlaceholder: "Map your location",
  billingAddress: "Billing Address",
  address: "Address",
  area: "Area",
  city: "City",
  state: "State",
  zipCode: "Zip Code",
  useSameBillingAddress: "Use the same address for billing",
  gst: "GSTIN",
  gstPlaceholder: "Enter GSTIN number",
  kycLabel: "KYC and Bank Account Details",
  accountType: "Account Type",
  bankName: "Bank Name",
  accountName: "Account Name",
  accountNumber: "Account Number",
  ifsc: "IFSC",
  pan: "PAN",
  cancelledCheque: "Cancelled Cheque",
  createOrderButton: "+ Create Order",
  createOrderMobileButton: "+ Order",
  createTransferOrderButton: "+ Create Transfers Order",
  createMobileTransferOrderButton: "+ Transfer",
  createAdjustmentButton: "+ Create Adjustment",
  createMobileAdjustmentButton: "+ Adjustment",
  stockTransfersButton: "Stock Transfers",
  stockAdjustmentsButton: "Stock Adjustments",
  stockTransfersMobileButton: "Transfers",
  stockAdjustmentsMobileButton: "Adjustments",
  skuDealingLabel: "SKUs dealing with",
  saveButton: "Save",
  sameBillingAddressLabelText: "Use the same address for billing",
  selectIfUnregisteredText: "Select if unregistered",
  findButtonText: "Looking to store or deliver this order ?",
  paymentTermsText: "Payment Terms :",
  shipping: "Shipping :",
  otherItemsAndConditionsLabel: "Other terms and conditions :",
  amountInWordsLabel: "Amount in Words :",
  preparedByLabel: "Prepared By:",
  returnReasonLabel: "Return Reason :",
  remarksLabel: "Remarks",
  poTransactionLabel: "PO Transactions"
};

export const salesOrder_labels = {
  createOrder: "Create  Order",
  docType: "By Doc type",
  warehouse: "By Warehouse",
  status: "By Status",
  docNo: "By Doc No.",
  date: "From and To Dates",
  customer: "By Customer Name",
  vendor: "By Vendor Name",
  sku: "By SKU",
  salesman: "By Salesman",
  addRemoveHeader: "Add/ Remove Headers"
};

export const quotes_labels = {
  createquotes: "Create quotes",
  createquotesMobile: "+ Quote",
  docType: "By Doc type",
  status: "By Status",
  date: "From and To Dates",
  customer: "By Customer",
  salesman: "By Salesman",
  orderType: "Order Type",
  salesRepresentative: "Sales Representative",
  customerName: "Customer Name",
  customerBilling: "Customer Billing",
  dateOfDelivery: "Date of Delivery",
  paymentTerms: "Payment Terms",
  customerShipTo: "Customer ShipTo",
  shipping: "Shipping",
  termAndCondition: "Other terms and conditions :"
};

export const quotes_placeholder = {
  orderType: "Quote",
  salesRepresentative: "Select Sales Rep",
  customerName: "Select Customer",
  customerBilling: "Select Billing Address",
  dateOfDelivery: "select date",
  paymentTerms: "Due 30 days from receipt",
  customerShipTo: "Select ST - Code",
  shipping: "Buyer"
};

export const add_customer_labels = {
  customerDetailsLabel: "Customer Details",
  addCustomerLabel: "Add Customer",
  customerCompanyName: "Customer Company Name",
  contactName: "Contact Name",
  contact: "Contact",
  emailId: "Email Id",
  address: "Billing Address",
  area: "Area",
  city: "City",
  state: "State",
  code: "Zip Code",
  gstin: "GSTIN",
  gstinPlaceHolder: "Enter GSTIN number",
  unRegister: "Select if unregistered",
  accountType: "Account Type",
  bankName: "Bank Name",
  accountName: "Account Name",
  accountNumber: "Account Number",
  IFSC: "IFSC",
  PAN: "PAN",
  cancelledCheck: "Cancelled Cheque",
  addStore: "Add Stores/ Shipto locations",
  addStoreButton: "Add New Store",
  pointofContact: "Point of Contact",
  mobileNo: "Mobile No.",
  mapstore: "ShipTo Address",
  codeArea: "Code/ Area"
};

export const allocation_labels = {
  ManageSalesRep: "Manage Salesman",
  docType: "By Doc type",
  date: "From and To Dates",
  customer: "By Customer",
  salesman: "By Salesman",
  warehouses: "Warehouses"
};

export const mandotary = <span style={{ color: "#F05454" }}> *</span>;
